import "@/styles/globals.css";
// import "@/styles/assetsFiles/sass/style.sass";

import { FacebookPixels, GoogleScripts } from "@/components/analytics";
import Head from "next/head";
import { Fragment } from "react";

import { Inter } from "next/font/google";
// app comment
const inter = Inter({
  weight: ["400", "500", "600", "700"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
});

export default function App({ Component, pageProps }) {

  return (
    <Fragment>
      {/* <FacebookPixels />
      <GoogleScripts /> */}
      <Head>
        <meta charSet="utf-8" />
        <meta name="description" content />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        {/* Title */}
       
        <title>{process.env.NEXT_PUBLIC_TITLE}</title>
      </Head>


        <main className={inter.className}>
          <Component {...pageProps} />
        </main>
  
    </Fragment>
  );
}
